@tailwind base;
@tailwind components;
@tailwind utilities;

#tsparticles {
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 0;
}
